import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import GoogleIcon from "../icons/google-icon"
import ReactPaginate from "react-paginate"

class Reviews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            itemsPerPage: 4,
        }
    }

    handlePageChange = ({ selected }) => {
        this.setState({ currentPage: selected })
    }

    render() {
        const reviewData = this.props.data.allContentfulReviews.edges[0].node
        const { currentPage, itemsPerPage } = this.state
        const indexOfLastReview = (currentPage + 1) * itemsPerPage
        const indexOfFirstReview = indexOfLastReview - itemsPerPage
        const currentReviews = reviewData.reviews.slice(indexOfFirstReview, indexOfLastReview)
        return (
            <Layout>
                <Seo
                    title="Customer Reviews | Sell Your Home Fast for Cash | Dahlia Homes"
                    description="Read our Customer Reviews to see why homeowners trust Dahlia Homes to sell their houses fast for cash. Our satisfied clients share their experiences with our quick home buying process, fair cash offers, and hassle-free transactions. 
                    Whether they were facing foreclosure, dealing with inherited property, or needing to move quickly, our clients appreciate our transparent and efficient service. Discover how we make selling your home as-is simple and stress-free, and why so many choose 
                    us for their fast cash home sale needs. Browse through real testimonials and contact us today to get started with your own quick home sale."
                    keywords={[
                        "sell my house in sacramento",
                        "selling my house in sacramento",
                        "fair cash offer",
                        "fast cash home sale"
                    ]}
                    location={this.props.location.pathname}
                />
                <div className="bg-dhgray pt-20 pb-10 relative">
                    <div className="container mx-auto px-5 xl:px-[195px]">
                        <h2 className="text-sm text-dhred px-5">Google Reviews</h2>
                        <p className="px-5 font-bold text-2xl lg:text-3xl">Trusted By Hundreds Of Home Owners Each Year</p>
                        <p className="px-5 text-lg py-10">We prioritize ensuring that homeowners have an excellent experience when working with us. Ultimately, our satisfaction comes from successfully delivering the solutions that sellers seek. Take a look at our reviews below to see feedback from others.</p>
                        <div className="grid md:grid-cols-2 gap-y-8">
                            {currentReviews.map((review) => (
                                <div key={review.id} className="px-4 relative">
                                    <div className="bg-white p-6 rounded-lg shadow-md flex items-center" style={{ height: "400px" }}>
                                        <div>
                                            <p className="text-lg font-semibold">{review.author}</p>
                                            <p className="text-gray-500 text-sm mb-2">{review.date}</p>
                                            <div className="flex mb-2">
                                                {[...Array(review.rating)].map((_, index) => (
                                                    <svg key={index} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-yellow-400 fill-current mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                        <path d="M10 1l2.46 6.755L19 7.755l-5.612 4.628L15.888 19 10 15.875 4.112 19l1.5-6.617L1 7.755l6.54-.19L10 1z" />
                                                    </svg>
                                                ))}
                                            </div>
                                            <p className="text-gray-700">{review.review}</p>
                                        </div>
                                        <div className="absolute top-6 right-12 w-2 h-2" >
                                            <GoogleIcon />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center pt-12">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"mt-1.5"}
                                pageCount={Math.ceil(reviewData.reviews.length / itemsPerPage)}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={this.handlePageChange}
                                breakLinkClassName="px-1.5 py-1 rounded-full hover:rounded-full hover:bg-dhred transition duration-150 ease-in-out hover:text-dhred"
                                containerClassName="flex space-x-2"
                                activeLinkClassName="px-2 py-1 rounded-full bg-dhred text-white"
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                                pageClassName="pt-1"
                                pageLinkClassName="px-2 py-1 rounded-full hover:bg-dhred hover:rounded-full hover:text-white transition duration-150 ease-in-out"
                                renderOnZeroPageCount={null}
                                nextClassName='hover:text-dhred transition duration-150 ease-in-out'
                                previousClassName='hover:text-dhred transition duration-150 ease-in-out'
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default Reviews
export const pageQuery = graphql`
query {
    site {
        siteMetadata {
            title
        }
    }
    allContentfulReviews {
        edges {
          node {
            reviews {
                id
                author
                date
                rating
                review
                avatar
              }
          }
        }
      }
}
`